import React, { useState } from 'react';
import Row from 'react-bootstrap/Row';
import ProductCard from './components/ProductCard';
import './index.scss';

const Showcase = ({ products, location }) => {
  const [data, setData] = useState();

  const getProducts = async () => {
    const newObjects = [];
    Promise.all(
      products.map(async (product) => {
        const request = await import(`../../assets/img/${product.img}`);
        newObjects.push({ ...product, img: request.default });
      })
    ).then((r) => setData(r.default));
  };

  if (!data) {
    getProducts();
  }

  return (
    <Row xs={1} sm={1} md={2} lg={2} xl={3} className="g-2">
      {data &&
        data.map((product, idx) => (
          <ProductCard
            key={`card-${idx}`}
            imgUrl={product.img}
            title={product.title}
            text={product.text}
            footer={product.footer}
          />
        ))}
    </Row>
  );
};

export default Showcase;
