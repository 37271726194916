import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import './index.scss';

const Company = () => {
  return (
    <Container className="view-container screen-height">
      <Row className="justify-content-md-center">
        <Col>
          <h1>La empresa</h1>
          <p>
            Con más de dos décadas de experiencia en el mercado de la medicina y
            la bioquímica quienes formamos ONYVA damos un nuevo gran paso hacia
            el futuro, fieles a nuestro compromiso con nuestros clientes de
            brindar siempre la mejor calidad en nuestros productos, la precisión
            en nuestro servicio, y en todo nuestro mayor activo: la experiencia
            ganada.
          </p>
          <p>
            ONYVA -el nombre a nuestra empresa- nace de la expresión francesa
            &quot;on y va!&quot;, que en castellano significa algo asi como
            &quot; ¡allá vamos!&quot; y representa el espíritu innovador y
            emprendedor que nos caracteriza, que ofrecemos y ofreceremos siempre
            en el mercado local a través de nuestras lineas de productos de
            marcas de primerísima calidad&nbsp; a nivel mundial.
          </p>
          <iframe
            title="Mapa"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3284.2736970027186!2d-58.46466438455267!3d-34.59723996466274!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x95bcb5fe23a12aa3%3A0xadcdcabfc5b73867!2sONYVA+SRL!5e0!3m2!1ses!2sar!4v1479771552069"
            style={{ border: 0 }}
            allowfullscreen
            width="640"
            height="480"
            frameborder="0"
          />
        </Col>
      </Row>
    </Container>
  );
};

export default Company;
