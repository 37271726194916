import React from 'react';
import Carrousel from '../../components/Carrousel';
import './index.scss';

const Home = () => {
  return (
    <>
      <Carrousel />
    </>
  );
};

export default Home;
