export const data = {
  
    products: [
      {
        id: 1,
        code: 1234,
        img: 'prod-alfred.png',
        title: 'ALFRED 60: Screening bacteriológico',
        text: 'Pipeteo automático para 60 muestras con tubo primario, identificación de muestras y procesamiento automático. Cálculo de la escala de Mc Farland, Panel de ATB personalizado, Reporte de resultados.',
        footer: 'Actualizado el 11/10/2021',
        brand: 'alifax',
      },
      {
        id: 2,
        code: 4567,
        img: 'binding-spa-plus.png',
        title: 'HB&L UROCULTURE KIT',
        text: 'Consiste en medir el crecimiento bacteriano de una muestra de orina  inoculada en un medio líquido enriquecido. El software del HB&L traduce la señal informando al usuario el numero de UFC/ml.',
        footer: 'Actualizado el 21/10/2021',
        brand: 'alifax',
      },
      {
        id: 3,
        code: 8910,
        img: 'prod-anaerobios.jpg',
        title: 'HB&L ANAEROBE KIT X 60 TESTS',
        text: 'Kit de 60 tests para el screening rápido de microorganismos anaerobios. 60 viales de vidrio descartables. 1 ANAEROBE card. SHELF LIFE: 6 meses. CONDICIONES DE ALMACENAMIENTO: 4 a 8 °C',
        footer: 'Actualizado el 21/10/2021',
        brand: 'alifax',
      },
      {
        id: 4,
        code: 1234,
        img: 'prod-alfred.png',
        title: 'ALFRED 60: Screening bacteriológico',
        text: 'Pipeteo automático para 60 muestras con tubo primario, identificación de muestras y procesamiento automático. Cálculo de la escala de Mc Farland, Panel de ATB personalizado, Reporte de resultados.',
        footer: 'Actualizado el 11/10/2021',
        brand: 'binding',
      },
      {
        id: 5,
        code: 4567,
        img: 'binding-spa-plus.png',
        title: 'HB&L UROCULTURE KIT',
        text: 'Consiste en medir el crecimiento bacteriano de una muestra de orina  inoculada en un medio líquido enriquecido. El software del HB&L traduce la señal informando al usuario el numero de UFC/ml.',
        footer: 'Actualizado el 21/10/2021',
        brand: 'binding',
      },
      {
        id: 3,
        code: 8910,
        img: 'prod-anaerobios.jpg',
        title: 'HB&L ANAEROBE KIT X 60 TESTS',
        text: 'Kit de 60 tests para el screening rápido de microorganismos anaerobios. 60 viales de vidrio descartables. 1 ANAEROBE card. SHELF LIFE: 6 meses. CONDICIONES DE ALMACENAMIENTO: 4 a 8 °C',
        footer: 'Actualizado el 21/10/2021',
        brand: 'trinity',
      },
      {
        id: 6,
        code: 1234,
        img: 'prod-alfred.png',
        title: 'ALFRED 60: Screening bacteriológico',
        text: 'Pipeteo automático para 60 muestras con tubo primario, identificación de muestras y procesamiento automático. Cálculo de la escala de Mc Farland, Panel de ATB personalizado, Reporte de resultados.',
        footer: 'Actualizado el 11/10/2021',
        brand: 'eli',
      },
      {
        id: 7,
        code: 4567,
        img: 'binding-spa-plus.png',
        title: 'HB&L UROCULTURE KIT',
        text: 'Consiste en medir el crecimiento bacteriano de una muestra de orina  inoculada en un medio líquido enriquecido. El software del HB&L traduce la señal informando al usuario el numero de UFC/ml.',
        footer: 'Actualizado el 21/10/2021',
        brand: 'eli',
      },
      {
        id: 8,
        code: 8910,
        img: 'prod-anaerobios.jpg',
        title: 'HB&L ANAEROBE KIT X 60 TESTS',
        text: 'Kit de 60 tests para el screening rápido de microorganismos anaerobios. 60 viales de vidrio descartables. 1 ANAEROBE card. SHELF LIFE: 6 meses. CONDICIONES DE ALMACENAMIENTO: 4 a 8 °C',
        footer: 'Actualizado el 21/10/2021',
        brand: 'coris',
      },
    ],
    brands: {
      alifax: {links: [{name: 'Laser Scattering', path: 'laser-scattering'},
      {name: 'Screening bacteriológico', path: 'screening'},
       {name: 'Urocultivos', path: 'urocultivos'},
        {name: 'Multiresistentes', path: 'multiresistentes'}]},
        binding: {links: []},
        trinity: {links: []},
        coris: {links: []},
        eli: {links: []},
    }
  }