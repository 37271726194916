import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import { Document, Page, pdfjs } from 'react-pdf';
import './index.scss';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const CustomModal = ({ show, setShow, pdfName }) => {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [pdf, setPdf] = useState(undefined);

  (async () => {
    if (pdfName !== '') {
      await import(`../../assets/docs/${pdfName}.pdf`).then((r) => {
        setPdf(r.default);
      });
    }
  })();

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const handleExit = () => {
    setNumPages(null);
    setPageNumber(1);
  };

  return (
    <>
      <Modal
        show={show}
        onHide={() => setShow(false)}
        dialogClassName="modal-100w"
        size="xl"
        aria-labelledby="example-custom-modal-styling-title"
        onExit={handleExit}
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-custom-modal-styling-title">
            Documento: {pdfName}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="text-center">
            <Document
              file={pdf}
              onLoadSuccess={onDocumentLoadSuccess}
              loading={<Spinner animation="border" variant="success" />}
            >
              <Page pageNumber={pageNumber} height={1000} width={1000} />
            </Document>
            <p>
              Page {pageNumber} of {numPages}
            </p>
            <Button
              onClick={() => setPageNumber(pageNumber - 1)}
              disabled={pageNumber === 1}
            >
              Back
            </Button>
            <Button
              onClick={() => setPageNumber(pageNumber + 1)}
              disabled={pageNumber >= numPages}
            >
              Next
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default CustomModal;
