import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Navigation from './components/Navigation';
import Footer from './components/Footer';
import Home from './views/Home';
import Line from './views/Line';
import News from './views/News';
import Company from './views/Company';
import Contact from './views/Contact';
import './App.scss';

function App() {
  return (
    <Router>
      <Navigation />
      <Switch>
        <Route exact path="/" component={Home} />
        <Route path="/lines" component={Line} />
        <Route path="/news" component={News} />
        <Route path="/meet-us" component={Company} />
        <Route path="/contact-us" component={Contact} />
      </Switch>
      <Footer />
    </Router>
  );
}

export default App;
