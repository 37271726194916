import React from 'react';
import Carousel from 'react-bootstrap/Carousel';
import imgLabEnv from '../../assets/img/lab-environment.jpg';
import imgLabLiq from '../../assets/img/lab-liquids.png';
import imgLabMic from '../../assets/img/lab-microscope.jpg';
import './index.scss';

const Carrousel = () => {
  return (
    <Carousel className="carousel" variant="dark" touch slide>
      <Carousel.Item>
        <img className="d-block w-100" src={imgLabEnv} alt="First slide" />
        <Carousel.Caption>
          <h5>First slide label</h5>
          <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img className="d-block w-100" src={imgLabLiq} alt="Second slide" />
        <Carousel.Caption>
          <h5>Second slide label</h5>
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img className="d-block w-100" src={imgLabMic} alt="Third slide" />
        <Carousel.Caption>
          <h5>Third slide label</h5>
          <p>
            Praesent commodo cursus magna, vel scelerisque nisl consectetur.
          </p>
        </Carousel.Caption>
      </Carousel.Item>
    </Carousel>
  );
};

export default Carrousel;
