import React from 'react';
import { Link } from 'react-router-dom';
import fbIcon from '../../assets/icons/facebook.svg';
import igIcon from '../../assets/icons/instagram.svg';
import liIcon from '../../assets/icons/linkedin.svg';
import Container from 'react-bootstrap/Container';
import Image from 'react-bootstrap/Image';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import './index.scss';

const Footer = () => {
  return (
    <Container className="footer">
      <Row>
        <Col xs={1} sm={0} md={2} />
        <Col xs={4} sm={2} md={1}>
          <ul>
            <li>
              <strong>
                <Link to="/">Inicio</Link>
              </strong>
            </li>
            <li>
              <Link to="/">Instucional</Link>
            </li>
            <li>
              <Link to="/">Novedades</Link>
            </li>
            <li>
              <Link to="/">Contacto</Link>
            </li>
          </ul>
        </Col>
        <Col xs={6} sm={2} md={2}>
          <ul>
            <li>
              <strong>Líneas</strong>
            </li>
            <li>
              <Link to="/">Binding Site</Link>
            </li>
            <li>
              <Link to="/">Ali Fax</Link>
            </li>
            <li>
              <Link to="/">Trinity Biotech</Link>
            </li>
            <li>
              <Link to="/">Coris BioConcept</Link>
            </li>
            <li>
              <Link to="/">ELI Tech Group</Link>
            </li>
          </ul>
        </Col>
        <Col xs={12} sm={2} md={2} className="responsive-centered">
          <p>
            Dr. Adolfo Dickman 990 <br />
            (C1416EHB) CABA
            <br />
            Buenos Aires - ARGENTINA
          </p>
        </Col>
        <Col xs={12} sm={2} md={2} className="responsive-centered">
          <p>
            Tel: (+54-11) 5235-3970
            <br />
            ventas@onyva.com.ar
            <br />
            <br />
            Horario de atención:
            <br />
            Lunes a Viernes 9 a 18Hs. (GMT-3)
          </p>
        </Col>
        <Col xs sm md={1} className="social-links">
          <ul>
            <li>
              <a
                className="facebook"
                href="https://www.facebook.com/soyhenryok/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Image src={fbIcon} />
              </a>
            </li>
            <li>
              <a
                className="instagram"
                href="https://www.instagram.com/soyhenry_ok/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Image src={igIcon} />
              </a>
            </li>
            <li>
              <a
                className="linkedin"
                href="https://www.linkedin.com/school/soyhenry/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Image src={liIcon} />
              </a>
            </li>
          </ul>
        </Col>
      </Row>
      <Row>
        <Col span={12} className="text-center">
          ©2016 ONYVA SRL - Todos los derechos reservados
        </Col>
      </Row>
    </Container>
  );
};

export default Footer;
