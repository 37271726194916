import React from 'react';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import InfoButton from '../InfoButton';
import './index.scss';

const ProductCard = ({ imgUrl, title, text, footer }) => {
  return (
    <Col>
      <Card className="product-card">
        <Card.Img
          variant="top"
          src={imgUrl}
          style={{
            maxHeight: '200px',
            maxWidth: 'fit-content',
            width: 'auto',
            height: 'auto',
          }}
        />

        <Card.Body>
          <Card.Title>{title || 'Card title'}</Card.Title>
          <Card.Text>
            {text ||
              'This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.'}
          </Card.Text>
          <InfoButton />
        </Card.Body>
        <Card.Footer>
          <small className="text-muted">
            {footer || 'Last updated 3 mins ago'}
          </small>
        </Card.Footer>
      </Card>
    </Col>
  );
};

export default ProductCard;
