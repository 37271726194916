import React, { useState } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ListGroup from 'react-bootstrap/ListGroup';
import Showcase from '../../components/Showcase';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import { HashLink } from 'react-router-hash-link';

import CustomModal from '../../components/CustomModal';

import './index.scss';
import { data } from '../../json/products';

const Line = ({ location }) => {
  const pathSplit = location.pathname.split('/');
  const brand = pathSplit[pathSplit.length - 1];

  const [show, setShow] = useState(false);
  const [activeLink, setActiveLink] = useState('');
  const [pdf, setPdf] = useState('');

  const handleClick = (document) => {
    setShow(true);
    setPdf(document);
  };

  return (
    <Container className="view-container">
      <Row className="justify-content-md-center">
        <Col xs md={3} lg={3}>
          <Row>
            <Col>
              <ListGroup id="nav-menu">
                {data.brands[brand].links.map((link, idx) => (
                  <HashLink
                    key={`${link.path}-${idx}`}
                    smooth
                    scroll={(el) =>
                      el.scrollIntoView({ behavior: 'auto', block: 'end' })
                    }
                    className="no-decoration-nav"
                    to={`#${link.path}`}
                    onClick={() => setActiveLink(`${link.path}`)}
                  >
                    <ListGroup.Item
                      action
                      active={activeLink === `${link.path}`}
                    >
                      {link.name}
                    </ListGroup.Item>
                  </HashLink>
                ))}
              </ListGroup>
            </Col>
          </Row>
        </Col>
        <Col>
          <Showcase
            products={data.products.filter(
              (product) => product.brand === brand
            )}
          />
          <div>
            <Table responsive="lg">
              <thead>
                <tr>
                  <th>Característica</th>
                  <th>Método manual</th>
                  <th>HB&L Alfred</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <span className="badge bg-success text-wrap">
                      Resultado positivo
                    </span>
                  </td>
                  <td>24 a 48 horas</td>
                  <td>A partir de los 45 minutos</td>
                </tr>
                <tr>
                  <td>2</td>
                  <td>Table cell</td>
                  <td>Table cell</td>
                </tr>
                <tr>
                  <td>3</td>
                  <td>Table cell</td>
                  <td>Table cell</td>
                </tr>
              </tbody>
            </Table>
          </div>
          <Button
            variant="primary"
            onClick={(e) => handleClick(e.target.innerText)}
          >
            ImmuGlo Anti-Mielin
          </Button>
          <Button
            variant="primary"
            onClick={(e) => handleClick(e.target.innerText)}
          >
            ImmcoStripe
          </Button>
          <Button
            variant="primary"
            onClick={(e) => handleClick(e.target.innerText)}
          >
            IFA
          </Button>
          <CustomModal show={show} setShow={setShow} pdfName={pdf} />
          <div id="dynamic-content">
            {data.brands[brand].links.map((link, idx) => (
              <div key={`${link.path}-${idx}`} id={`${link.path}`}>
                <h3>{link.name}</h3>
                <p>
                  Lorem, ipsum dolor sit amet consectetur adipisicing elit.
                  Mollitia beatae nam officia aliquid autem neque amet tenetur
                  aperiam consequatur impedit maiores, fuga minima porro illo
                  harum repellendus commodi officiis recusandae?
                </p>
              </div>
            ))}
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default Line;
