import React from 'react';
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Image from 'react-bootstrap/Image';
import SearchBar from './components/SearchBar';
import { LinkContainer } from 'react-router-bootstrap';
import logo from '../../assets/icons/logo-onyva.png';
import './index.scss';

const Navigation = () => {
  return (
    <Navbar
      collapseOnSelect
      expand="lg"
      bg="light"
      variant="light"
      className="bottom-border-green sticky-top"
    >
      <Container>
        <LinkContainer to="/">
          <Navbar.Brand>
            <Image src={logo} width="200" />
          </Navbar.Brand>
        </LinkContainer>

        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto">
            <LinkContainer to="/meet-us">
              <Nav.Link className="no-decoration-nav">Institucional</Nav.Link>
            </LinkContainer>
            <NavDropdown title="Líneas" id="collasible-nav-dropdown">
              <LinkContainer to="/lines/binding" className="no-decoration">
                <NavDropdown.Item>Binding Site</NavDropdown.Item>
              </LinkContainer>
              <LinkContainer to="/lines/alifax" className="no-decoration">
                <NavDropdown.Item>Ali Fax</NavDropdown.Item>
              </LinkContainer>
              <LinkContainer to="/lines/trinity" className="no-decoration">
                <NavDropdown.Item>Trinity Biotech</NavDropdown.Item>
              </LinkContainer>
              <LinkContainer to="/lines/coris" className="no-decoration">
                <NavDropdown.Item>Coris BioConcept</NavDropdown.Item>
              </LinkContainer>
              <LinkContainer to="/lines/eli" className="no-decoration">
                <NavDropdown.Item>ELI Tech Group</NavDropdown.Item>
              </LinkContainer>

              <NavDropdown.Divider />
              <NavDropdown.Item>Catálogo completo</NavDropdown.Item>
            </NavDropdown>
            <LinkContainer className="no-decoration-nav" to="/news">
              <Nav.Link>Novedades</Nav.Link>
            </LinkContainer>
            <LinkContainer className="no-decoration-nav" to="/contact-us">
              <Nav.Link>Contacto</Nav.Link>
            </LinkContainer>
          </Nav>
          <Nav>
            <SearchBar />
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default Navigation;
