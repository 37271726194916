import React from 'react';
import InputGroup from 'react-bootstrap/InputGroup';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Form from 'react-bootstrap/Form';
import './index.scss';
import { useState } from 'react';

const SearchBar = () => {
  const [title, setTitle] = useState('Todo el sitio');

  return (
    <InputGroup className="mb-3">
      <DropdownButton
        variant="outline-secondary"
        title={title}
        id="input-group-dropdown-1"
      >
        <Dropdown.Item onClick={(e) => setTitle(e.target.innerText)}>
          Diagnóstico
        </Dropdown.Item>
        <Dropdown.Item onClick={(e) => setTitle(e.target.innerText)}>
          Estética
        </Dropdown.Item>
        <Dropdown.Item onClick={(e) => setTitle(e.target.innerText)}>
          Odontología
        </Dropdown.Item>
        <Dropdown.Divider />
        <Dropdown.Item onClick={(e) => setTitle(e.target.innerText)}>
          Todo el sitio
        </Dropdown.Item>
      </DropdownButton>
      <Form.Control
        aria-label="Text input with dropdown button"
        placeholder="Buscar..."
      />
    </InputGroup>
  );
};

export default SearchBar;
