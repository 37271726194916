import React from 'react';
import Popover from 'react-bootstrap/Popover';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Button from 'react-bootstrap/Button';
import Image from 'react-bootstrap/Image';
import pdfFileIcon from '../../../../assets/icons/pdf-file.svg';
import './index.scss';

const InfoButton = ({ header, body, buttonText }) => {
  const popover = (
    <Popover id="popover-basic">
      <Popover.Header as="h3">{header ?? `Ver más sobre...`}</Popover.Header>
      <Popover.Body>
        {body ??
          `And here's some <strong>amazing</strong> content. It's very engaging.
        right?`}
      </Popover.Body>
    </Popover>
  );

  return (
    <>
      <OverlayTrigger trigger="click" placement="top" overlay={popover}>
        <Button variant="success">{buttonText ?? 'Más información'}</Button>
      </OverlayTrigger>
      <Image src={pdfFileIcon} />
    </>
  );
};

export default InfoButton;
